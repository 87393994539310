
import Contactinfo from '~/components/ContactInfo.vue'
export default {
  components: {
    Contactinfo
  },
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head () {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title
    }
  },
  data () {
    return {
      pageNotFound: this.$i18n.t('deleted_offer'),
      otherError: this.$i18n.t('error_occurred')
    }
  },
  watch: {
    // Reload offer content when switching language
    '$i18n.locale' () {
      this.pageNotFound = this.$i18n.t('deleted_offer')
      this.otherError = this.$i18n.t('error_occurred')
    }
  }
}
