export default ({ app }, inject) => {
  // $formatPrice
  inject('formatPrice', (price) => {
    const value = parseFloat(price)
    const val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '') + '€'
  })

  // formatDate
  inject('formatDate', (d) => {
    // Cut of time from date to prevent rounding errors
    const timeCutDate = new Date(d.substring(0, 10))
    // convert to german date format
    return timeCutDate.toLocaleDateString('de-DE')
  })

  // removeHtmlTags
  inject('removeHTMLTags', (str) => {
    if (str === null || str === '') { return false } else { str = str.toString() }
    return str.replace(/(<([^>]+)>)/gi, '')
  })

  // Checks if date is expired
  inject('isNotExpired', (date) => {
    if (new Date(date) - Date.now() > 0) {
      return true
    } else { return false }
  })

  // check if there are any active discounts
  inject('hasActiveDiscounts', (discounts) => {
    // Checks if date is expired
    function isNotExpired (date) {
      if (new Date(date) - Date.now() > 0) {
        return true
      } else { return false }
    }

    let activeDiscounts = 0
    if (discounts.length <= 0) {
      return false
    } else {
      // check every discount for its expiration
      for (const discount of discounts) {
        if (isNotExpired(JSON.parse(JSON.stringify(discount)).date_end)) {
          activeDiscounts++
        }
      }
      // if active discounts exist return true
      if (activeDiscounts > 0) { return true } else { return false }
    }
  })
}
