import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _13e7599c = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _1feff9d2 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _5fbd8934 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _59794f36 = () => interopDefault(import('../pages/invite/_code/index.vue' /* webpackChunkName: "pages/invite/_code/index" */))
const _70decb38 = () => interopDefault(import('../pages/zusatzangebot/_token.vue' /* webpackChunkName: "pages/zusatzangebot/_token" */))
const _5604a0b8 = () => interopDefault(import('../pages/invite/_code/anmeldung.vue' /* webpackChunkName: "pages/invite/_code/anmeldung" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _13e7599c,
    pathToRegexpOptions: {"strict":true},
    name: "error"
  }, {
    path: "/success",
    component: _1feff9d2,
    pathToRegexpOptions: {"strict":true},
    name: "success"
  }, {
    path: "/",
    component: _5fbd8934,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/invite/:code",
    component: _59794f36,
    pathToRegexpOptions: {"strict":true},
    name: "invite-code"
  }, {
    path: "/zusatzangebot/:token?",
    component: _70decb38,
    pathToRegexpOptions: {"strict":true},
    name: "zusatzangebot-token"
  }, {
    path: "/invite/:code?/anmeldung",
    component: _5604a0b8,
    pathToRegexpOptions: {"strict":true},
    name: "invite-code-anmeldung"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
