
import LanguageSwitcher from '~/components/LanguageSwitcher.vue'

export default {
  components: {
    LanguageSwitcher
  },
  data () {
    return {
      clipped: false,
      drawer: false,
      fixed: true,
      items: [
        {
          icon: 'mdi-apps',
          title: 'Welcome',
          to: '/'
        }
      ],
      miniVariant: false,
      right: false,
      rightDrawer: false,
      title: 'Vuetify.js'
    }
  }
}
